<template>
  <v-navigation-drawer
    id="hb-navigation"
    class="pa-0"
    :class="{ 'isMobile': $vuetify.breakpoint.xs }"
    :width="$vuetify.breakpoint.smAndUp ? 60 : '100%'"
    clipped
    disable-resize-watcher
    hide-overlay
    app
    elevation-1
    mobile-breakpoint="1"
    flat
  >
    <v-layout
      :column="$vuetify.breakpoint.smAndUp"
      :fill-height="$vuetify.breakpoint.smAndUp"
      :row="$vuetify.breakpoint.xs"
      :wrap="$vuetify.breakpoint.xs"
    >
      <v-list
        :row="$vuetify.breakpoint.xs"
        :wrap="$vuetify.breakpoint.xs"
        class="mt-sm-1"
        :class="{ 'isMobile': $vuetify.breakpoint.xs }"
        style="padding-left: 9px;"
        dense
      >

        <v-list-item class="pl-0 pr-1 my-sm-1" :class="{ 'pl-3' : $vuetify.breakpoint.xs }" :style="$vuetify.breakpoint.xs ? 'padding-top:3px;' : ''">
          <hb-btn
            icon
            tooltip="Search"
            active-state-off
            @click="goSearch"
            :disabled="!isHBUser"
          >
            mdi-magnify
          </hb-btn>
        </v-list-item>
        <v-list-item class="pl-0 pr-1 my-sm-1">
          <hb-btn
            icon
            tooltip="Quick Actions"
            active-state-off
            @click="goQuickLinks"
            :disabled="!isHBUser"
          >
            mdi-flash-circle
          </hb-btn>
        </v-list-item>

        <v-list-item v-if="isAdmin" class="pl-0 pr-1 my-sm-1">
            <hb-btn
              icon tooltip="Create Task"
              :active="todo"
              @click="todo = true"
              :disabled="!isHBUser"
            >mdi-calendar-today</hb-btn>
            <todo v-model="todo" v-if="todo"></todo>
        </v-list-item>
        

        <v-list-item class="pl-0 pr-1 my-sm-1">
            <hb-btn
              type="icon"
              tooltip="Tasks"
              active-state-off
              @click="goTaskCenter"
              :disabled="!isHBUser"
            >
              <template slot-scope="{ hover }">
                <v-badge
                  :value="(tasksCounter && tasksCounter > 0) || showTasksIndicator"
                  color="#F26500"
                  overlap
                  dot
                  offset-x="8"
                >
                  <hb-icon
                    :hover="hover"
                  >mdi-playlist-check</hb-icon>
                </v-badge>
              </template>
            </hb-btn>
        </v-list-item>

         <v-list-item class="pl-0 pr-1 my-sm-1">
            <hb-btn
              type="icon"
              tooltip="Messages"
              active-state-off
              @click="goCommunicationCenter"
              :disabled="!isHBUser"
            >

              <template slot-scope="{ hover }">
                <v-badge
                  v-if="messageCount" 
                  :content="messageCount > 99 ? '99+' : messageCount"
                  :value="true"
                  color="#F26500"
                  overlap
                  offset-x="18"
                  offset-y="6"
                  >
                  <hb-icon :hover="hover">mdi-forum</hb-icon>
                </v-badge>
                <hb-icon v-else :hover="hover">mdi-forum</hb-icon>
              </template>
            </hb-btn>
        </v-list-item>
      </v-list>
      <v-spacer></v-spacer> 
      <v-list
        :row="$vuetify.breakpoint.xs"
        :wrap="$vuetify.breakpoint.xs"
        :class="{ 'isMobile': $vuetify.breakpoint.xs }"
        dense
      >
       <v-list-item v-show="showAppLauncher" class="my-sm-1 pr-1" :style="$vuetify.breakpoint.xs ? 'padding-left:0;' : 'padding-left:9px;'">
          <v-menu
            v-model="showLauncher"
            center
            offset-y
            transition="slide-x-transition"
            nudge-top="700"
            nudge-width="336"
            max-width="100%"
            content-class="platformApp"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{on,attrs}">
              <hb-tooltip>
                <template v-slot:item>
                  <v-btn
                    type="icon"
                    tooltip="Applications"
                    class="app-launcher-icon-btn"
                    active-state-off
                    plain
                    depressed
                    v-on="on"
                    v-bind="attrs"
                    @click="launcherDrawer = !launcherDrawer"
                  >
                    <hb-icon>mdi-apps</hb-icon>
                  </v-btn>
                </template>
                <template v-slot:body>
                    <span>
                        Applications
                    </span>
                </template>
              </hb-tooltip>
            </template>
            <app-launcher :launcher-drawer.sync="launcherDrawer" 
            @drawerOpened="handleDrawerChange"
            @closeAppLauncher="showLauncher = !showLauncher"
            ></app-launcher>
          </v-menu>
        </v-list-item>

        <v-list-item class="my-sm-1 pr-1" :style="$vuetify.breakpoint.xs ? 'padding-left:0;' : 'margin-left:2px;'">
          <help-juice nav />
        </v-list-item>
        
        <v-list-item v-if="!isSettings" class="my-sm-1 pr-1" :style="$vuetify.breakpoint.xs ? 'padding-left:0;' : 'padding-left:9px;'">
          <hb-btn
            icon
            tooltip="Settings"
            @click="showSettingsModal"
            active-state-off
          >

                mdi-settings

          </hb-btn>
        </v-list-item>
        <v-list-item class="pr-3 mb-sm-4 mt-sm-2" :style="$vuetify.breakpoint.xs ? 'padding-left:0;' : 'padding-left:14px;'">
          <v-avatar color="#101318" size="32">
            <span class="white--text" style="font-size:18px;">{{contact.first.substring(0, 1).toUpperCase()}}</span>
          </v-avatar>
        </v-list-item>
      </v-list>
    </v-layout>

  </v-navigation-drawer>
</template>
<script>
import NavLink from './NavLink.vue';
import NotificationsList from '../Notifications/List.vue';
// import TodoCreate from '../Todo/Create.vue';
import Todo from '../Todo.vue';
import { mapGetters, mapActions } from 'vuex';
import { EventBus } from '../../../EventBus.js';
import api from '../../../assets/api.js'
import AppLauncher from "../../applications/AppLauncher";
export default {

    props: ['isSettings'],
    data() {
        return {
            showLauncher: false,
            showAppLauncher: false,
            showAppDialog: false,
            showSettings: false,
            drawer: true,
            launcherDrawer: false,
            globalDownload: false,
            downloadStatus: false,
            exportLoading: false,
            todo: false,
            headerNavLinks: [
              {
                title: 'Dashboard',
                to:'/dashboard',
                class: 'align-self-center',
                activeClass:'active',
                //action: () => { this.$store.commit('setNavState', { open: !this.$store.getters.isNavOpen}) },
                svg: {
                    x:'0px',
                    y:'0px',
                    style:'enable-background:new 0 0 24 24;',
                    viewBox: '0 0 24 24',
                    path: {
                        fill: '#788f9b',
                        class: 'icon-path',
                        d: 'M10 20V14H14V20H19V12H22L12 3L2 12H5V20H10Z'
                    }
                } //M2.02002 12C2.02002 6.49002 6.49002 2.02002 12 2.02002C17.51 2.02002 21.98 6.49002 21.98 12C21.98 17.51 17.51 21.98 12 21.98C6.49002 21.98 2.02002 17.51 2.02002 12ZM11.48 13.74V20L16.35 10.26H13V4.00002L8.00002 13.74H11.48Z
            },
            {
                title: 'Search',
                class: 'align-self-center',
                activeClass:'active',
                action: {
                  command: 'HB-Navigation:Search',
                  payload: {
                    foo: 'bar'
                  }
                },
                svg: {
                    x:'0px',
                    y:'0px',
                    style:'enable-background:new 0 0 24 24;',
                    viewBox: '0 0 24 24',
                    path: {
                        class: 'icon-path',
                        d: 'M15.502 14H14.708L14.432 13.726C15.407 12.589 16 11.115 16 9.50003C16 5.91003 13.09 3.00003 9.5 3.00003C5.91 3.00003 3 5.91003 3 9.50003C3 13.09 5.91 16 9.5 16C11.115 16 12.588 15.408 13.725 14.434L14.001 14.708V15.5L18.999 20.491L20.49 19L15.502 14ZM9.5 14C7.014 14 5 11.986 5 9.50003C5 7.01503 7.014 5.00003 9.5 5.00003C11.985 5.00003 14 7.01503 14 9.50003C14 11.986 11.985 14 9.5 14Z'
                    }
                }
             },
                {
                title: 'Offline',
                class: 'align-self-center',
                activeClass:'active',
                action: {
                  command: 'HB-Navigation:QuickLinks',
                  payload: {
                    foo: 'bar'
                  }
                },
                svg: {
                    x:'0px',
                    y:'0px',
                    style:'enable-background:new 0 0 24 24;',
                    viewBox: '0 0 24 24',
                    path: {
                        class: 'icon-path',
                        d: 'M2.02002 12C2.02002 6.49002 6.49002 2.02002 12 2.02002C17.51 2.02002 21.98 6.49002 21.98 12C21.98 17.51 17.51 21.98 12 21.98C6.49002 21.98 2.02002 17.51 2.02002 12ZM11.48 13.74V20L16.35 10.26H13V4.00002L8.00002 13.74H11.48Z'
                    }
                }
              }
            ],
            footerNavLinks: [
              {
                title: 'Supervised User',
                to:'/user',
                class: 'align-self-center',
                activeClass:'active',
                action: () => { this.$store.commit('setNavState', { open: !this.$store.getters.isNavOpen}) },
                svg: {
                    x:'0px',
                    y:'0px',
                    style:'enable-background:new 0 0 24 24;',
                    viewBox: '0 0 24 24',
                    path: {
                        class: 'icon-path',
                        d: 'M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM15.61 8.34C16.68 8.34 17.54 9.2 17.54 10.27C17.54 11.34 16.68 12.2 15.61 12.2C14.54 12.2 13.68 11.34 13.68 10.27C13.67 9.2 14.54 8.34 15.61 8.34ZM11.97 9.12C11.97 7.82 10.91 6.76 9.61 6.76C8.3 6.76 7.25 7.81 7.25 9.12C7.25 10.42 8.31 11.48 9.61 11.48C10.91 11.48 11.97 10.42 11.97 9.12ZM9.61 15.89V19.64C7.21 18.89 5.31 17.04 4.47 14.68C5.52 13.56 8.14 12.99 9.61 12.99C10.14 12.99 10.81 13.07 11.51 13.21C9.87 14.08 9.61 15.23 9.61 15.89ZM11.21 19.96C11.47 19.99 11.73 20 12 20C15.39 20 18.28 17.88 19.45 14.91C18.53 14.15 16.68 13.76 15.61 13.76C14.15 13.76 11.21 14.47 11.21 15.89V19.96Z'
                    }
                }
            },
              {
                title: 'Apps',
                to:'/market',
                class: 'align-self-center',
                activeClass:'active',
                action: () => { this.$store.commit('setNavState', { open: !this.$store.getters.isNavOpen}) },
                svg: {
                    x:'0px',
                    y:'0px',
                    style:'enable-background:new 0 0 24 24;',
                    viewBox: '0 0 24 24',
                    path: {
                        class: 'icon-path',
                        d: 'M4 8H8V4H4V8ZM10 20H14V16H10V20ZM8 20H4V16H8V20ZM4 14H8V10H4V14ZM14 14H10V10H14V14ZM16 4V8H20V4H16ZM14 8H10V4H14V8ZM16 14H20V10H16V14ZM20 20H16V16H20V20Z'
                    }
                }
            },
                {
                title: 'Settings',
                to:'/settings',
                class: 'align-self-center',
                activeClass:'active',
                //action: () => { this.$store.commit('setNavState', { open: !this.$store.getters.isNavOpen}) },
                svg: {
                    x:'0px',
                    y:'0px',
                    style:'enable-background:new 0 0 24 24;',
                    viewBox: '0 0 24 24',
                    path: {
                        class: 'icon-path',
                        d: 'M19.5001 12C19.5001 12.34 19.4701 12.66 19.4301 12.98L21.5401 14.63C21.7301 14.78 21.7801 15.05 21.6601 15.27L19.6601 18.73C19.5401 18.95 19.2801 19.04 19.0501 18.95L16.5601 17.95C16.0401 18.34 15.4801 18.68 14.8701 18.93L14.4901 21.58C14.4601 21.82 14.2501 22 14.0001 22H10.0001C9.75008 22 9.54008 21.82 9.51008 21.58L9.13008 18.93C8.52008 18.68 7.96008 18.35 7.44008 17.95L4.95008 18.95C4.73008 19.03 4.46008 18.95 4.34008 18.73L2.34008 15.27C2.22008 15.05 2.27008 14.78 2.46008 14.63L4.57008 12.98C4.53008 12.66 4.50008 12.33 4.50008 12C4.50008 11.67 4.53008 11.34 4.57008 11.02L2.46008 9.37C2.27008 9.22 2.21008 8.95 2.34008 8.73L4.34008 5.27C4.46008 5.05 4.72008 4.96 4.95008 5.05L7.44008 6.05C7.96008 5.66 8.52008 5.32 9.13008 5.07L9.51008 2.42C9.54008 2.18 9.75008 2 10.0001 2H14.0001C14.2501 2 14.4601 2.18 14.4901 2.42L14.8701 5.07C15.4801 5.32 16.0401 5.65 16.5601 6.05L19.0501 5.05C19.2701 4.97 19.5401 5.05 19.6601 5.27L21.6601 8.73C21.7801 8.95 21.7301 9.22 21.5401 9.37L19.4301 11.02C19.4701 11.34 19.5001 11.66 19.5001 12ZM8.50008 12C8.50008 13.93 10.0701 15.5 12.0001 15.5C13.9301 15.5 15.5001 13.93 15.5001 12C15.5001 10.07 13.9301 8.5 12.0001 8.5C10.0701 8.5 8.50008 10.07 8.50008 12Z'
                    }
                }
            },{
                title: 'properties',
                to:'/properties',
                class: 'align-self-center',
                activeClass:'active',
                //action: () => { this.$store.commit('setNavState', { open: !this.$store.getters.isNavOpen}) },
                svg: {
                    x:'0px',
                    y:'0px',
                    style:'enable-background:new 0 0 64 64;',
                    viewBox: '0 0 64 64',
                    path: {
                        class: 'icon-path',
                        d: 'M0.6,9.6v49c0,0.6,0.4,1,1,1h61.1c0.6,0,1-0.4,1-1v-49c0-0.6-0.4-1-1-1H1.6C1.1,8.6,0.6,9.1,0.6,9.6z M57.7,54.7h-5.8c-0.6,0-1-0.4-1-1V33.2h2.3c0.4,0,0.7-0.3,0.7-0.7v-3.5c0-0.4-0.3-0.7-0.7-0.7H11.1c-0.4,0-0.7,0.3-0.7,0.7v3.5c0,0.4,0.3,0.7,0.7,0.7h2.3v20.5c0,0.6-0.4,1-1,1H6.6c-0.6,0-1-0.4-1-1v-39c0-0.6,0.4-1,1-1h51.1c0.6,0,1,0.4,1,1v39C58.7,54.2,58.3,54.7,57.7,54.7z'
                    }
                }
            }
          ],
          tasksLength: 0,
          pendingMoveIns: [],
          showTasksIndicator: false
        }
    },
    computed: {
        ...mapGetters({
          contact: 'authenticationStore/getUserData',
          isAdmin: 'authenticationStore/isAdmin',
          tasksCount: 'taskCenterStore/tasksCount',
          isHBUser: 'authenticationStore/isHBUser',
          messageCount: 'notificationsStore/messageCount'
        }),
        getGdsDomain() {
          return process.env.VUE_APP_GDS_BASE_URL +"/v3";
        },
        getGdsApiKey() {
          return process.env.VUE_APP_GDS_API_KEY;
        },

        initial(){
            return this.contact.first ? this.contact.first.substring(0,1): 'H';
        },
        tasksCounter(){
          let count = 0;
          if (this.tasksCount && this.tasksCount.length) {
            count = count + this.tasksCount.find( arrEl => arrEl.event_type_name == 'all').count;
          }
          if (this.pendingMoveIns.length && this.pendingMoveIns.length > 0) {
            count = count + this.pendingMoveIns.length;
          }
          return count;
        }
    },
    methods: {
      closeAppLauncher(val) {
        this.showLauncher = val
      },
      handleDrawerChange(val) {
        this.launcherDrawer = val
      },
      ...mapActions({
          setTaskCounts: 'taskCenterStore/setTaskCounts',
          getMessageCount: 'notificationsStore/getMessageCount'
      }),
      showSettingsModal() {
        EventBus.$emit('HB-Navigation:showSettingsModal');
      },
      hideSettingsModal() {
        this.showSettings = false;
        let element = document.querySelector('body')
        element.classList.remove('hide-scroll')  
      },
      closeExport(){
        this.globalDownload = false;
        this.exportLoading = false;
        this.downloadStatus = false;
      },
      exportLoad() {
        this.exportLoading = true;
        this.downloadStatus = true;
        setTimeout(() => {
          this.exportLoading = false;
        }, 2000);
      },
      goSearch(){
        var payload = { foo: 'bar' };
        EventBus.$emit('HB-Navigation:Search', payload);
      },
      goQuickLinks(){
        var payload = { foo: 'bar' };
        EventBus.$emit('HB-Navigation:QuickLinks', payload);
      },
      goDelinquencyCenter() {
        EventBus.$emit('HB-Navigation:DelinquencyCenter');
      },
      goLeadFollowUp() {
        EventBus.$emit('HB-Navigation:LeadFollowUp');
      },
      goTaskCenter() {
        EventBus.$emit('HB-Navigation:TaskCenter');
      },
      goCommunicationCenter() {
        EventBus.$emit('HB-Navigation:CommunicationCenter');
      },
      async findPending(){
          let r = await api.get(this, api.LEASES +  'pending', { property_id: '' });
          this.pendingMoveIns = r.leases;
      },
      eventShowSettings(){
        this.showSettings = false;
        this.showAppDialog = false
      },
      eventShowTasksIndicator(value){
        this.showTasksIndicator = value;
      },

      closeAppDialog() {
        this.showAppDialog = false
        this.$store.dispatch('navigationStore/setAppLauncher', null);
      },
      hasPlatformApplications() {
        let isPlatformIntegrationEnabled = this.$store.getters['authenticationStore/getPlatformIntegrationEnabled']
        let hasPlatformApps = this.$store.getters['authenticationStore/hasPlatformApps']
        return !!(isPlatformIntegrationEnabled && hasPlatformApps)
      }
    },
    components: {
      AppLauncher,
      // TodoCreate,
      Todo,
      NavLink,
      NotificationsList,
      'app-settings': () => import('../../settings/Index.vue'),
      'app-dialog': () => import('../../applications/AppDialog')
    },
    created() {
      this.showAppLauncher = this.hasPlatformApplications()
      this.setTaskCounts({ role_ids: this.rolesIDs });
      this.findPending();
      this.getMessageCount();
    },
}
</script>
<style lang="scss">

.hide-scroll {
  overflow: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;  
}

#hb-navigation {
  display: flex;
  flex-direction: column;
  width: 60px;
  transform: translateX(0%) !important;
  visibility: unset !important;
  .v-list.isMobile {
    display: flex !important;
    flex-direction: row;
    padding: 8px 15px;
    .v-list-item {
      padding: 0 10px;
    }
  }
  &.isMobile {
    height: 60px !important;
    bottom: 0;
    position: fixed;
    top: initial !important;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
      .v-navigation-drawer__content {

      }
  }
}

.platformApp.menuable__content__active.theme--light.v-menu__content {
    left: 0px !important;
    top: 0px !important;
    overflow:  hidden;
    position: fixed;
    z-index: 255 !important;
}
.app-launcher-icon-btn.v-btn.v-btn--depressed.theme--light.v-size--default {
  height: 40px;
  min-width: 40px !important;
  background-color: transparent !important;
  padding: 0px !important;
  border-radius: 50% !important;
}


    /*#hb-nav {*/
    /*    min-width: 56px;*/
    /*    display: flex;*/
    /*    align-items: stretch;*/
    /*    .hb-nav--inner {*/
    /*        position: fixed;*/
    /*        top: 0;*/
    /*        left: 0;*/
    /*        bottom: 0;*/
    /*        background: rgba(255,255,255,1);*/
    /*        border-right: 1px solid rgba(0,0,0,0.25);*/
    /*        width: 56px;*/
    /*        padding-top:70px;*/
    /*        &>.row {*/
    /*            padding: 5px;*/
    /*        }*/
    /*        .row {*/
    /*            span.nav-icon{*/
    /*                display: inline-block;*/
    /*                height: 24px;*/
    /*                width: 24px;*/
    /*                vertical-align: top;*/
    /*                margin-right: 0px;*/
    /*            }*/
    /*            .col {*/
    /*                padding: 0;*/
    /*            }*/
    /*        }*/
    /*        v-col {*/
    /*            text-align: center;*/
    /*        }*/
    /*    }*/
    /*    .hb-nav--footer {*/

    /*    }*/
    /*}*/






    /********************
    Navigation Styles
   *********************/

    .nav{
      transition: all .3s;
      transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }

    span.nav-header {
      display: block;
      margin: 35px 0 10px 45px;
    }

    span.nav-header h3 {
      font-size: 12px;
      font-family: "Lato", sans-serif;
      font-weight: 700;
      color: #788f9b;
      letter-spacing: 1px;
      text-transform: uppercase;

    }

    .nav-wrapper{
      overflow-x: hidden;
    }
    .navigation {
      width: 100%;
      position: relative;
    }
    .navigation ul {
      margin: 0 0 0 0px;
      padding: 0;
      list-style: none;

    }

    .navigation ul li{
      padding: 0px 0;
      margin: 0;
    }



    /*.v-navigation-drawer__content a{*/
    /*    color: #788f9b;*/
    /*    font-family: "Roboto", sans-serif;*/
    /*    font-weight: 500;*/
    /*    font-size: 14px;*/
    /*    cursor: pointer;*/
    /*    line-height: 20px;*/
    /*    display: block;*/

    /*    padding: 7px 5px 7px 45px;*/
    /*    position: relative;*/
    /*    fill: currentColor;*/
    /*    -webkit-transition: .6s cubic-bezier(0.77, 0, 0.175, 1);*/
    /*    -moz-transition: .6s cubic-bezier(0.77, 0, 0.175, 1);*/
    /*    -o-transition: .6s cubic-bezier(0.77, 0, 0.175, 1);*/
    /*    border-top: 1px solid #FFFFFF;*/
    /*    border-bottom: 1px solid #FFFFFF;*/

    /*}*/

    .icon-path-dark{
      -webkit-transition: .6s cubic-bezier(0.77, 0, 0.175, 1);
      -moz-transition: .6s cubic-bezier(0.77, 0, 0.175, 1);
      -o-transition: .6s cubic-bezier(0.77, 0, 0.175, 1);
      fill: #788f9b;
    }


    .icon-path{
      -webkit-transition: .6s cubic-bezier(0.77, 0, 0.175, 1);
      -moz-transition: .6s cubic-bezier(0.77, 0, 0.175, 1);
      -o-transition: .6s cubic-bezier(0.77, 0, 0.175, 1);
      fill: #788f9b;
    }

    .v-navigation-drawer__content a.v-list-item--active .icon-path{
      fill: #00b2ce;
    }

    .v-navigation-drawer__content a.v-list-item--active .icon-path-dark{
      fill: #00a1c8;
      fill: #263238;
    }

    /* this style shouldn't be necessary, commenting it out
    .v-navigation-drawer__content a.v-list-item--active{
      color: #00a1c8;
      border-top: 1px solid #e4eff4;
      border-bottom: 1px solid #e4eff4;
    }
    */

    .navigation ul li a,
    .navigation ul li a:hover,
    .navigation ul li a:visited,
    .navigation ul li a:active{
      text-decoration: none;
    }
    span.nav-text {
      line-height: 20px;
    }
    span.nav-icon{
      display: inline-block;
      height: 20px;
      width: 20px;
      vertical-align: top;
    }

    .company-switcher{
      margin: 0 10px 10px;
      display: none;
      padding: 15px 30px 0;
    }

    .close-link-row {
      display: none;
    }

    .v-progress-circular {
      margin: 1rem;
    }

    .v-dialog{
      box-shadow: 0 !important;
    }

    .notifications-custom-padding{
      padding-left:11px;
    }
    .task-indicator{
      position: absolute;
      padding: 4.5px;
      background-color: red;
      border-radius: 50%;
      right: 25%;
      top: 25%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }

    @media (max-width: 991px){

      .nav {
        margin-left: -225px;
        width: 200px;
        position: fixed;
        top: 0px;
        bottom: 0;
        overflow: auto;
        z-index: 7000;


      }

      .nav-open .nav{
        margin-left: 0px;
        width: 100%;
      }

      .company-switcher{
        display:block;
      }

      .close-link-row {
        display: block;
        height: 30px;
      }


    }



    @media (max-width: 893px) {

      .navbar-toggle {
        display: block;
      }

    }
    @media (max-width: 768px) {
      .sm-remove{
        display:none;
      }
      /*.nav-wrapper{*/
      /*transform:translateX(-225px);*/
      /*}*/
      /*.nav-open .nav-wrapper{*/
      /*transform:translateX(0px);*/
      /*width: 100%;*/
      /*}*/

      /*.nav-open .content-container{*/
      /*transform:translateX(225px);*/
      /*}*/

      .nav-open .navigation .hide-small{
        opacity: 1;
      }
    }

    @media (min-width: 600px){



    }


</style>
